import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { FunctionComponent } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useSetStateFromProp } from '../Hooks';

export enum TabNavType {
    TAB = 'tab',
    BORDERED = 'bordered',
    PILL = 'pill'
}

interface TabContainerProps
{
    tabs: Tab[];
    navType?: TabNavType;
    isJustified?: boolean;
    /** The active tab key. */
    activeKey?: string;
    /** Called when the active tab should change. If false is returned, the tab change is canceled. */
    onTabChange?: (activeKey: string) => boolean;
}

/**
 * Component for displaying tabs.
 * https://react-bootstrap.github.io/components/tabs/#custom-tab-layout
 * 
 * @param props 
 */
const TabContainer: FunctionComponent<TabContainerProps> = (props) =>
{
    const [activeKey, setActiveKey] = useState(props.activeKey ?? props.tabs[0].eventKey);
    useSetStateFromProp(setActiveKey, props.activeKey ?? props.tabs[0].eventKey);

    useEffect(() =>
    {
        // Check if the current active tab still exists
        const activeTabExists = props.tabs.some(tab => tab.eventKey === activeKey);

        // If it doesn't, set the active tab to the last one
        if (!activeTabExists && props.tabs.length > 0) 
        {
            setActiveKey(props.tabs[props.tabs.length - 1].eventKey);
        }
    }, [props.tabs]);

    /** Navigation classe (https://themes.coderthemes.com/hyper_react/ui/base-ui/tabs) */
    let navClasses = ['nav'];
    if (props.navType === TabNavType.TAB || props.navType == TabNavType.BORDERED || props.navType === undefined)
    {
        navClasses.push('nav-tabs');
    }
    if (props.navType === TabNavType.BORDERED || props.navType === undefined)
    {
        navClasses.push('nav-bordered');
    }
    if (props.navType === TabNavType.PILL)
    {
        navClasses.push('nav-pills', 'bg-nav-pills');
    }
    if (props.isJustified)
    {
        navClasses.push('nav-justified');
    }

    /** Nav item */
    let cmpNavItems = props.tabs.map(tab =>
        <Nav.Item key={tab.eventKey} as="li">
            <Nav.Link
                eventKey={tab.eventKey}
                disabled={tab.isEnabled === false}
            >
                <i className={classnames('d-md-none', 'd-block', 'me-1')}></i>
                <span className="d-none d-md-block">{tab.title}</span>
            </Nav.Link>
        </Nav.Item>
    );

    /** Panes */
    let cmpPanes = props.tabs.map(tab =>
        <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey} id={tab.eventKey}>
            {tab.content}
        </Tab.Pane>
    )

    /** Render */
    return (
        <Tab.Container
            activeKey={activeKey}
            onSelect={(k) =>
            {
                if (k && (props.onTabChange ? props.onTabChange(k) : true))
                {
                    setActiveKey(k);
                }
            }}
        >
            <Nav variant="tabs" className={`${navClasses.join(' ')} mb-3`} as="ul">
                {cmpNavItems}
            </Nav>

            <Tab.Content>
                {cmpPanes}
            </Tab.Content>
        </Tab.Container>
    );
}

export type Tab = {
    eventKey: string,
    title: string,
    content: JSX.Element,
    isEnabled?: boolean
};

export default TabContainer;