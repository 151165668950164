import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppActionDispatch } from '@redux/hooks';
import { setIsUserLoggedIn } from '@xFrame4/redux/userSlice';
import { AuthUser } from '@xFrame4/business/users/AuthUser';
import { useTranslate } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import ConfirmDialog, { ConfirmDialogType, ConfirmDialogResult } from '@xFrame4/components/common/ConfirmDialog';
import EntityAddEditForm from '@xFrame4/components/admin/EntityAddEditForm';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';

interface MyAccountViewProps
{

}

const MyAccountView: FunctionComponent<MyAccountViewProps> = () =>
{
    const t = useTranslate();
    const navigate = useNavigate();
    const dispatch = useAppActionDispatch();
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<AuthUser>(AuthUser.current!);
    const [confirmedPassword, setConfirmedPassword] = useState<string>(''); 
    const [isDeleteAccountConfirmOpen, setIsDeleteAccountConfirmOpen] = useState<boolean>(false);

    /** Validate form. */
    const onValidate = (): boolean => {
        let isValid = true;
        
        // Validate password change
        if (entity?.password !== undefined && entity?.password != '')
        {
            isValid = entity.password == confirmedPassword;
        }

        return isValid;
    };

    /** Save the user. */
    const onSave = async () =>
    {
        let success = false;
        
        success = await entity!.save();
        if (success)
        {
            // Re-login the user to reload the fields on the AuthUser.current singleton instance
            success = await AuthUser.loginFromJWT();
        }

        return success;
    };

    /** Delete the user. */
    const deleteUser = async () => 
    {
        let result = await AuthUser.current?.delete();
        if (result)
        {
            dispatch(setIsUserLoggedIn(false));
            await AuthUser.logout();
        }
    }

    /** Close the view. */
    const onClose = () =>
    {
        navigate('/');
    };

    /** Delete the user  */
    let cmpDeleteConfirmDialog =
    <ConfirmDialog
        type={ConfirmDialogType.YesNo}
        title={t('DELETE_MY_ACCOUNT_CONFIRM')}
        isOpen={isDeleteAccountConfirmOpen}
        onClose={(result) => {
            if (result == ConfirmDialogResult.Yes)
            {
                deleteUser();
            }
            setIsDeleteAccountConfirmOpen(false);
        }}
    />;
    
    /** Tab: general */
    let cmpTabGeneral = 
    <>
        <Row>
            <Col xs={12}>
                <FieldEditor
                    type={FieldEditorType.Text}
                    field="email"
                    label={t('EMAIL')}
                    isEnabled={false}
                    value={entity?.email}
                />
            </Col>
            <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.Text}
                    field="firstName"
                    label={t('FIRST_NAME')}
                    value={entity?.firstName}
                    onValueChanged={(field, value) => updateEntityField(field, value)}
                />
            </Col>
            <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.Text}
                    field="lastName"
                    label={t('LAST_NAME')}
                    value={entity?.lastName}
                    onValueChanged={(field, value) => updateEntityField(field, value)}
                />
            </Col>
            <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.Text}
                    field="nickname"
                    label={t('NICKNAME')}
                    value={entity?.nickname}
                    onValueChanged={(field, value) => updateEntityField(field, value)}
                />
            </Col>
        </Row>

        <hr />
        <h4 className="header-title mb-3">
            {t('CHANGE_YOUR_PASSWORD')}
        </h4>
        <Row>
            <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.Password}
                    field="password"
                    label={t('NEW_PASSWORD')}
                    value={entity?.password}
                    onValueChanged={(field, value) => {updateEntityField(field, value);  if (value == '') setConfirmedPassword('')}}
                />
            </Col>
            <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.Password}
                    field="confirmedPassword"
                    label={t('CONFIRM_NEW_PASSWORD')}
                    isRequired={entity?.password !== undefined && entity?.password != ''}
                    value={confirmedPassword}
                    onValueChanged={(field, value) => setConfirmedPassword(value)}
                />
            </Col>
        </Row>
    </>;

    /** Render */
    return (
        <>
            <EntityAddEditForm
                entity={entity}
                mode={AddEditFormMode.Edit}
                title={t('EDIT_YOUR_PERSONAL_DETAILS')}
                onValidate={() => onValidate()}
                onSave={() => onSave()}
                onClose={() => onClose()}
            >
                <TabContainer 
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>

            <Row className="pt-2 pb-5">
                <Col className="text-end">
                    <Button 
                        variant="danger" 
                        type="submit" 
                        onClick={() => setIsDeleteAccountConfirmOpen(true)}
                    >
                        {t('DELETE_MY_ACCOUNT')}
                    </Button>
                </Col>
            </Row>
            {cmpDeleteConfirmDialog}
        </>
    );
}

export default MyAccountView;