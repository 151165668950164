import { FunctionComponent, useState } from 'react';
import { useLoad } from '@xFrame4/components/Hooks';
import CheckboxList from '@xFrame4/components/common/CheckboxList';
import Group from '@xFrame4/business/users/Group';

interface GroupsEditorProps
{
    selectedGroups: Group[];
    onChange: (groups: Group[]) => void;
}

/**
 * Component: Editor for group selection
 */
const GroupsEditor: FunctionComponent<GroupsEditorProps> = (props) =>
{
    const [groups, setGroups] = useState<Group[]>([]);

    useLoad(async () => {
        // load the groups
        const groupResult = await Group.manager.load();
        if (groupResult) setGroups(groupResult.entities);
    });
    
    /** Render */
    return (
        <>
            <CheckboxList
                dataSource={groups}
                dataSourceDisplayMember="name"
                dataSourceValueMember="id"
                value={props.selectedGroups}
                onChange={(selectedItems) => props.onChange(selectedItems as Group[])}
            />
        </>
    );
}

export default GroupsEditor;