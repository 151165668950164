import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';

interface DashboardProps
{

}

const DashboardView: FunctionComponent<DashboardProps> = (props) =>
{
    const t = useTranslate();

    /** Render */
    return (
        <>
            
        </>
    );
}

export default DashboardView;