import React from 'react';
import { FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';

interface EntityPageTitleProps
{
    title: string
}

const EntityPageTitle: FunctionComponent<EntityPageTitleProps> = (props) =>
{
    /** Render */
    return (
        <>
            <Row>
                <Col>
                    <div className="page-title-box">
                        <div className="page-title-right">
                            
                        </div>
                        <h4 className="page-title">{props.title}</h4>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default EntityPageTitle;