import { FunctionComponent } from 'react';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { setAppLanguage } from '@redux/layoutSlice';
import CustomAdminLayout from './CustomAdminLayout';
import config from '@config';

interface AdminLayoutProps
{
    
}

const AdminLayout: FunctionComponent<AdminLayoutProps> = (props) =>
{
    const dispatch = useAppActionDispatch();
    const appLanguage = useAppStateSelector(state => state.layout.language);

    /** Set language from the local storage */
    if (localStorage.getItem('adminAppLanguage') && appLanguage.code !== localStorage.getItem('adminAppLanguage'))
    {
        let languageCode = localStorage.getItem('adminAppLanguage');
        let language = config.languages.find(language => language.code === languageCode);
        if (language)
        {
            dispatch(setAppLanguage({ language: language, saveToLocalStorage: false }));
        }
    }
    
    /** Render */
    return (
        <>
            <CustomAdminLayout />
        </>
    );
}

export default AdminLayout;