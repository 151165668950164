import { ApolloQueryResult, gql } from '@apollo/client';
import client from '@xFrame4/business/GraphQlClient';

export class NextJsHelper
{
    /**
     * Revalidates the modified Next.js pages.
     * For this to work:
     * - The NEXTJS_APP_REVALIDATE_TOKEN env variable must exist for Next.js and the API backend
     * - The Next.js api/revalidate.ts must check the key
     * - The target is the page to revalidate. By default every page is revalidated (all). You can also specify custom targets like 'blog', 'blog/[slug]', 'products', 'product_[id]', etc.
     */
    static async revalidate(target = 'all')
    {
        // Get the secret URL via the API
        let queryText = `
        query NextJsRevalidationUrl($target: String) {
            nextJsRevalidationUrl(target: $target)
        }          
        `
        
        let { data } = await client.query({
            query: gql(queryText),
            variables: {
                target: target
            }
        });
        
        let url = data.nextJsRevalidationUrl;

        // Revalidate
        console.log('Revalidating Next.js:');
        let response = await fetch(url);
        console.log('Revalidation result:');
        console.log(await response.json());
    }
}

export default NextJsHelper;