import { MenuItemType, SideBarWidth } from '@components/hyper/appConstants';
import config, { AppLanguage } from '@config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface LayoutState
{
    language: AppLanguage;
    leftSideBarType: SideBarWidth;
    dynamicMenuItems: MenuItemType[];
}

const initialState: LayoutState = {
    language: config.defaultLanguage,
    leftSideBarType: SideBarWidth.LEFT_SIDEBAR_TYPE_FIXED,
    dynamicMenuItems: []
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setAppLanguage: (state, action: PayloadAction<{ language: AppLanguage, saveToLocalStorage: boolean }>) => {
            state.language = action.payload.language;
            if (action.payload.saveToLocalStorage) 
            {
                localStorage.setItem('adminAppLanguage', action.payload.language.code);
            }
        },
        changeSidebarType: (state, action: PayloadAction<SideBarWidth>) => {
            state.leftSideBarType = action.payload;
        },
        setDynamicMenuItems: (state, action: PayloadAction<MenuItemType[]>) => {
            state.dynamicMenuItems = action.payload;
        }
    }
});

export const { setAppLanguage, changeSidebarType, setDynamicMenuItems } = layoutSlice.actions;

export default layoutSlice.reducer;