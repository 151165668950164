import React, { FormEvent, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Form, Alert } from 'react-bootstrap';
import AuthContainer from './AuthContainer';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useAppActionDispatch } from '@redux/hooks';
import { setIsUserLoggedIn } from '@xFrame4/redux/userSlice';
import { useTranslate } from '@xFrame4/components/Hooks';
import { AuthUser } from '@xFrame4/business/users/AuthUser';


interface LoginViewProps
{

}

const LoginView: FunctionComponent<LoginViewProps> = (props) =>
{
    const t = useTranslate();
    const navigate = useNavigate();
    const dispatch = useAppActionDispatch();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [validated, setValidated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasLoginFailed, setHasLoginFailed] = useState<boolean>(false);

    /**
     * Log in.
     */
    const logIn = async () => {
        setIsLoading(true);

        let result = await AuthUser.login(email, password);
        if (result) 
        {
            dispatch(setIsUserLoggedIn(true));
            navigate('/');
        }
        else
        {
            setHasLoginFailed(true);
        }

        setIsLoading(false);
    };

    /** Form submit */
    const onSubmit = (event: FormEvent<HTMLFormElement>) =>
    {
        event.preventDefault();
        event.stopPropagation();

        // Validate
        const form = event.currentTarget;
        if (form.checkValidity() === true) // Standard validation
        {
            // Log in
            logIn();
        }

        setValidated(true);
    };

    /** Bottom links */
    const bottomLinks = 
    <Row className="mt-3">
        <Col className="text-center">
            <p className="text-muted">
                {t('SIGN_IN_NO_ACCOUNT')}{' '}
                <Link to={'/register'} className="text-muted ms-1">
                    <b>{t('SIGN_UP')}</b>
                </Link>
            </p>
        </Col>
    </Row>
    
    /** Render */
    return (
        <AuthContainer bottomLinks={bottomLinks}>
            <div className="text-center w-75 m-auto">
                <h4 className="text-dark-50 text-center mt-0 fw-bold">{t('SIGN_IN')}</h4>
                <p className="text-muted mb-4">
                    {t('SIGN_IN_DESC')}
                </p>
            </div>

            <>
            {hasLoginFailed && (
                <Alert variant="danger" className="my-2">
                    {t('INVALID_CREDENTIALS')}
                </Alert>
            )}
            </>

            <Form noValidate validated={validated} onSubmit={(event) => onSubmit(event)}>
                <FieldEditor 
                    type={FieldEditorType.Email}
                    field="email"
                    label={t('EMAIL')}
                    isRequired={true}
                    placeholder={t('ENTER_YOUR_EMAIL_ADDRESS')}
                    value={email}
                    onValueChanged={(field, value) => setEmail(value)}
                />
                <FieldEditor 
                    type={FieldEditorType.Password}
                    field="password"
                    label={t('PASSWORD')}
                    isRequired={true}
                    placeholder={t('ENTER_YOUR_PASSWORD')}
                    value={password}
                    onValueChanged={(field, value) => setPassword(value)}
                />

                <div className="mb-3 mb-0 text-center">
                    <Button variant="primary" type="submit" disabled={isLoading}>
                        {t('SIGN_IN')}
                    </Button>
                </div>
            </Form>
        </AuthContainer>
    );
}

export default LoginView;