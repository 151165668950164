import { FunctionComponent } from 'react';
import React from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import EntityView from '../EntityView';
import { AddEditFormMode } from '../AddEditForm';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import GroupAddEditForm from './GroupAddEditForm';
import User, { Group } from '@xFrame4/business/users/Group';

interface GroupViewProps
{

}

const GroupView: FunctionComponent<GroupViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<User>(User);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<User>>(
        () => [
            { field: 'id', headerName: t('ID'), width: 50 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <GroupAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<Group>
                id="groups"
                title={t('GROUPS')}
                manager={Group.manager}
                baseFilter={{}}
                pageSize={20}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'name_Icontains', label: t('SUBJECT'), type: FieldEditorType.Text},
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default GroupView;